// src/components/sections/AboutSection.tsx
import React from 'react';
import { Mail, Linkedin } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';
import { Section } from '../shared/Section';
import profileImage from '../../assets/Group 2.png'; // Import the image

type SocialLinkProps = {
  icon: LucideIcon;
  href: string;
  label: string;
};

const SocialLink: React.FC<SocialLinkProps> = ({ icon: Icon, href, label }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 text-[#004B49] hover:text-[#006664] transition-colors"
    >
      <Icon size={20} color="currentColor" />
      <span>{label}</span>
    </a>
  );
};

export const AboutSection: React.FC = () => (
  <Section id="about" title="WORK IN PROGRESS - About Me">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-start">
      <div className="flex flex-col space-y-6">
        <div className="prose prose-lg">
          <p className="text-gray-600 text-lg leading-relaxed">
            Product Manager with a passion for building user-centric solutions. 
            Experienced in leading cross-functional teams and delivering impactful products 
            that solve real user problems.
          </p>
        </div>
        <div className="space-y-3">
          <h3 className="text-lg font-semibold text-[#004B49]">Connect with me</h3>
          <div className="space-y-2">
            <SocialLink icon={Mail} href="mailto:grittydv@gmail.com" label="grittydv@gmail.com" />
            <SocialLink icon={Linkedin} href="https://linkedin.com/in/sandormoricz" label="LinkedIn" />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-start">
        <img 
          src={profileImage}
          alt="Profile illustration"
          className="w-64 h-64 object-contain"
        />
      </div>
    </div>
  </Section>
);