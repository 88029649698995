// src/components/sections/ValuesSection.tsx
import React from 'react';
import { Section } from '../shared/Section';

const values = [
  { title: 'Grit', content: 'Persistence in the face of challenges, maintaining focus and determination to achieve long-term goals despite obstacles.' },
  { title: 'Trust', content: 'Building and maintaining honest relationships with teammates, stakeholders, and users through transparency and reliability.' },
  { title: 'Empathy', content: 'Understanding and sharing the feelings of others, creating products that truly resonate with user needs and pain points.' },
  { title: 'Innovation', content: 'Constantly seeking creative solutions to complex problems, embracing new technologies and methodologies.' }
];

export const ValuesSection = () => (
  <Section id="values" title="Values">
    <div className="flex flex-col space-y-6">
      {values.map(({ title, content }) => (
        <div key={title} className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-3">{title}</h3>
          <p className="text-gray-600">{content}</p>
        </div>
      ))}
    </div>
  </Section>
);
