import React from 'react';
import { User, Package, Heart, Mail } from 'lucide-react';
import { Navigation } from './components/Navigation';
import { AboutSection } from './components/sections/AboutSection';
import { ResourcesSection } from './components/sections/ResourcesSection';
import { ValuesSection } from './components/sections/ValuesSection';
import { ContactSection } from './components/sections/ContactSection';
import { useActiveSection } from './hooks/useActiveSection';
import { NavItem } from './types';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBURK_AHg8QSrP6C7Tt66SrlFLk4G-K6iI",
  authDomain: "gritty.digital",
  projectId: "onlypp",
  storageBucket: "onlypp.appspot.com",
  messagingSenderId: "381860474862",
  appId: "1:381860474862:web:7c8ef04a1858f2fb7368ff",
  measurementId: "G-4RS29S132X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const navItems: NavItem[] = [
  { id: 'about', label: 'About Me', icon: User },
  { id: 'resources', label: 'Product Resources', icon: Package },
  { id: 'values', label: 'Values', icon: Heart },
  { id: 'contact', label: 'Where you can reach me', icon: Mail },
];

const styles = `
  @font-face {
    font-family: 'Avenir Next';
    src: local('Avenir Next');
  }

  * {
    font-family: 'Avenir Next', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
`;
const App = () => {
    const activeSection = useActiveSection(navItems);
  
    const scrollToSection = (id: string) => {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    };
  
    return (
      <div className="min-h-screen bg-gray-50">
        <style>{styles}</style>
        <Navigation 
          items={navItems}
          activeSection={activeSection}
          onNavigate={scrollToSection}
        />
        <main className="space-y-[10vh]">
          <AboutSection />
          <ResourcesSection />
          <ValuesSection />
          <ContactSection />
        </main>
      </div>
    );
  };
  
  export default App;
