// src/components/Navigation.tsx
import React from 'react';
import { NavItem } from '../types';

type NavigationProps = {
  items: NavItem[];
  activeSection: string;
  onNavigate: (id: string) => void;
};

export const Navigation: React.FC<NavigationProps> = ({ items, activeSection, onNavigate }) => (
  <nav className="fixed top-0 right-4 h-screen flex items-center z-50">
    <div className="bg-white rounded-full shadow-lg py-4 px-2">
      <div className="flex flex-col space-y-6">
        {items.map(({ id, label, icon: LucideIcon }) => (
          <button
            key={id}
            onClick={() => onNavigate(id)}
            className={`group relative flex items-center justify-center p-2 rounded-full transition-all duration-300 hover:bg-[#004B49]/10
              ${activeSection === id ? 'text-[#004B49]' : 'text-gray-600'}`}
          >
            <LucideIcon size={24} strokeWidth={1.5} className="text-[#004B49]" />
            <span className="absolute right-full mr-2 px-2 py-1 bg-[#004B49] text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
              {label}
            </span>
          </button>
        ))}
      </div>
    </div>
  </nav>
);