import React from 'react';
import { Compass, Zap, Target } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';
import { Section } from '../shared/Section';
import misalignment from '../../assets/misalignmentBW.webp'; // Import the image
import funnel from '../../assets/productFunnel.jpeg'; // Import the image
import essential from '../../assets/essentialismEnergy.jpeg'; // Import the image

type Resource = {
  title: string;
  icon: LucideIcon;
  description: string;
  keywords: string[];
  imagePath: string;
};

const resources: Resource[] = [
  {
    title: "Strategic Direction",
    icon: Compass,
    description: "Define and build products that align perfectly with your business objectives. We help shape your product vision into a concrete roadmap for success.",
    keywords: ["Product Vision", "Strategy", "OKRs", "Market Research", "Competition Analysis", "SWOT Analysis"],
    imagePath: misalignment
  },
  {
    title: "Rapid Validation & Delivery",
    icon: Zap,
    description: "Transform ideas into reality through focused experimentation and efficient delivery. Our dual-track approach ensures efficient discovery and delivery.",
    keywords: ["Dual Scrum", "Discovery & Delivery", "Cross-Functional Leadership", "Customer Research", "Agile Methods"],
    imagePath: essential
  },
  {
    title: "Growth Acceleration",
    icon: Target,
    description: "Maximize your product's market impact through seamless collaboration with Sales, Marketing, and Customer Success teams. We help you acquire, activate, and retain customers.",
    keywords: ["AARRR Framework", "Product Analytics", "Success Metrics", "Customer Adoption"],
    imagePath: funnel
  }
];

export const ResourcesSection: React.FC = () => (
  <div className="min-h-screen p-8 bg-gray-50">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold mb-16 text-center bg-gradient-to-r from-emerald-600 to-emerald-500 bg-clip-text text-transparent">
        Supercharge Your Business Journey
      </h2>
      <div className="flex flex-col space-y-16">
        {resources.map(({ title, icon: Icon, description, keywords, imagePath }, index) => (
          <div 
            key={title} 
            className="bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-2xl group"
          >
            <div className={`flex flex-col md:flex-row ${index === 1 ? 'md:flex-row-reverse' : ''} items-stretch`}>
              <div className="flex-1 p-8 md:p-12 flex flex-col md:justify-center">
                <div className={`flex items-center gap-3 mb-6 ${index === 1 ? 'md:justify-end' : ''}`}>
                  <div className="p-2.5 bg-emerald-50 rounded-xl transition-colors group-hover:bg-emerald-100">
                    <Icon size={24} className="text-emerald-600" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">{title}</h3>
                </div>
                <p className={`text-gray-600 mb-8 leading-relaxed text-lg ${
                  index === 1 ? 'md:text-right' : ''
                }`}>
                  {description}
                </p>
                <div className={`flex flex-wrap gap-2 ${
                  index === 1 ? 'md:justify-end' : ''
                }`}>
                  {keywords.map((keyword) => (
                    <span
                      key={keyword}
                      className="px-3 py-1 text-sm bg-emerald-50 text-emerald-700 rounded-full transition-colors hover:bg-emerald-100"
                    >
                      {keyword}
                    </span>
                  ))}
                </div>
              </div>
              <div className={`md:w-[45%] relative bg-white flex items-center justify-center p-4 md:p-8 ${
                index === 1 ? 'md:h-[400px]' : index === 2 ? 'md:h-[300px]' : 'md:h-[350px]'
              }`}>
                <img
                  src={imagePath}
                  alt={title}
                  className="h-full w-full object-contain"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ResourcesSection;